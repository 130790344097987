import {observe} from '@github/selector-observer'

// Permalinking to a Gist comment is difficult because of the array
// of potential host names Gists can be served from (gist.github.com,
// github.com, lab, garage, enterprise environments...)
//
// The server-side helpers are not entirely trustworthy (see https://ghproxy.lvwenzhuo2003.xyz/github/github/issues/113982),
// so let's fill in the host name client-side where we definitely know
// what the host is.
observe('.js-gist-comment-permalink', function (element) {
  const path = element.getAttribute('data-href')
  element.textContent = `${window.location.origin}${path}`
})
